import React from 'react'
import Link from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

import PageHero from '../components/pageHero'
import Blockquote from '../components/sections/blockquote'
import ContentCenter from '../components/sections/contentCenter'

const WebsiteBuilder = () => (
	<Layout>
		<Seo title='Сделай сайт на конструкторе' />
		<PageHero
			title='Сделай сайт на конструкторе'
			description=' Это не Tilda и не Wix, а полноценный WordPess с удобным визуальным конструктором. И для создания сайта тебе не нужны навыки программирования. Если хочешь сразу начать, то просто нажимай на кнопку, регистрируйся и создавай свой сайт.'
			button='Создать свой сайт'
			link='https://www.reg.ru/web-sites/regsite/?rlink=reflink-6822007'
			image='/images/raccoon-011-1920.jpg'
		/>
		<Blockquote
			content='На этой странице мы рассказывем об особенностях создания сайта на конструкторе. Какие  у него есть возможности и ограничения. И подойдет ли такой сайт тебе.'
		/>
		<ContentCenter
			subtitle='Конструктор сайтов'
			title='Как сделать хороший сайт самостоятельно'
			intro='Здесь я не буду рассказывать тебе обо всех возможных конструкторах, а расскажу только об одном. Также не буду утверждать, что он самый лучший - это все субъективно и никакой пользы не несет. Я просто поделюсь своим опытом и может для тебя этот конструктор окажется самым полезным иструментом для создания твоего сайта.'
		>

			<p>Итак, речь о конструкторе сайтов REG.site который предлагает компания REG.RU. В основе этого конструктора WordPess и Divi. WordPress - это очень известная и удобная платформа для создания сайтов, а Divi - это конструктор страниц, при помощи которого можно создавать дизайн страниц вставляя и перетаскивая готовые модули и совершенно без знания программирования.</p>

			<blockquote>
				<p>Вообще красиво и рекламно про этот конструктор ты можешь почитать прямо на REG.RU. И прямо там же заказать и попробовать. Вот <a href='https://www.reg.ru/web-sites/regsite/?rlink=reflink-6822007' rel='nofollow' target='_blank'>жми сюда</a>.</p>
				<p>Промокод для скидки: <span className='text-bold'>339C-4A82-32CF-5B31</span></p>
				<p><a href='https://help.reg.ru/support/konstruktor-i-cms/reg-site/?rlink=reflink-6822007' rel='nofollow' target='_blank'>База знаний по REG.site</a></p>
				<p><a href='https://www.youtube.com/playlist?list=PL1NXAeRaMsACisa6o5jVsfXGmBYnYUpQb' rel='nofollow' target='_blank'>Видосы по REG.site</a></p>
			</blockquote>

			<p>Если тебе все же интересно узнать, что же там дальше написано, то продолжай читать ;) - расскажу тебе всю правду.</p>

			<h2>Почему REG.site</h2>

			<p>Расскажу как я выбирал. Мы сами создаем сайты по серьезному: с программированием, техническим заданием, дизайном, концепцией, seo и т.п. Это стоит денег и далеко не всем нужен серьезный навороченный сайт. Кому-то, вот как тебе, например, надо что-то доступное по цене, простое в управлении, с готовыми шаблонами и чтобы можно было быстро запустить. Также важно, чтобы основой конструктора была какая-то надежная платформа, которая давно существует и продолжает развиваться. А главное - это чтобы у тебя получилось сделать сайт самостоятельно.</p>

			<p>Возможно, в REG.RU думали также и сделали конструктор, в целом отвечающий подобным запросам. Поэтому сейчас выбор на их конструторе. Кроме того в REG.RU также можно купить и домены и хостинг и кучу других полезных вещей. А еще есть хорошая техподдержка и много материалов о том что и как сделать. Вот как-то так.</p>

			<h2>Для каких сайтов подойдет</h2>

			<p>Технически этот конструктор подойдет под любой типовой сайт: лэндинг, корпоративный многостраничник, блог, портфолио, небольшой интернет-магазин, сайт для хобби, сайт твоего питомца, сайт твоего дня рождения или твоя интернет-книга.</p>

			<p>Это вполне может быть и долгосрочный проект и сайт для мероприятия. Смотря как ты будешь к этому относиться и развивать.</p>

			<h2>Как устроен конструктор</h2>

			<p>По сути ты покупаешь хостинг с установленным WordPress, констроктором страниц Divi и готовыми шаблонами. У тебя будет доступы к панели управления хостингом, к базе данных и к админ-панели WordPress. В общем, все по-настоящему. Ты можешь устанавливать плагины, менять шаблон, настраивать макеты страниц и т.д.</p>

			<div class="aspect-w-16 aspect-h-9">
				<iframe src="https://www.youtube.com/embed/ZrLEdZdOL1g" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>

			<p>Функции постройки и изменения макетов дизайна заложены только в шаблонах и конструкторе страниц Divi, который уже установлен как обязательный плагин. В остальном это полноценный WordPess. Также есть плагин для быстрого поиска и добавления картинок с бесплатных медиа-хостингов Unsplash.com, Pixabay.com, Pexels.com. Это удобно.</p>

			<h2>Как сделать сайт на конструкторе</h2>

			<p>Подробно об этом написано в Базе знаний на сайте REG.ru. А еще у них в блоге есть статья <a href='https://www.reg.ru/blog/poshagovo-obyasnyaem-kak-samostoyatelno-sozdat-sajt-v-regsite/?rlink=reflink-6822007' rel='nofollow' target='_blank'>Пошагово объясняем как самостоятельно создать сайт в REG.Site</a>, поэтому здесь расскажу только основные моменты.</p>

			<p>Все сводится к нескольким простым шагам.</p>

			<ul>
				<li><span className='font-bold'>Покупаем услугу</span>. На странице <a href='https://www.reg.ru/web-sites/regsite/?rlink=reflink-6822007' rel='nofollow' target='_blank'>REG.site</a> жмем на кнопку выбрать тариф, выбираем тариф и следуем по шагам мастера заказа: домен, оплата. Это занимает минут 10, если не тупить :).</li>
				<li><span className='font-bold'>Выбираем шаблон</span>. После оплаты переходим в Услуги и кликаем по услуге «REG.site...» и выбираем шаблон. Шаблоны предварительно можно посмотреть. Тут можно подзависнуть, потому что шаблонов много, а выбрать нечего ;) (это для моего придирчивого дизайнерского взгляда, не обращай внимания). Можешь взять шаблон, который тебе больше подходит по структуре и дизайну, остальное можно позже подправить. Чтобы выбрать шаблон жми на «Выбрать»</li>
				<li><span className='font-bold'>Знакомимся с сайтом</span>. Жмем на кнопку Редактировать сайт или войти в Админку сайта. Все доступы находятся в панели управления услугой REG.site на вкладке Доступы. Попадаем в админку и тыкаемся везде, чтобы привыкнуть. Если ничего не понятно, читай документацию, сморти видосы и пробуй.</li>
				<li><span className='font-bold'>Вносим свой контент</span>. Когда наиграешься с конструктором и подготовишь контент, то просто вноси контент туда, где он должен быть.</li>
				<li><span className='font-bold'>Настраиваем все остальное</span>. После внесения контента нужно настроить SEO, имя сайта, логотип, цветовую схему, контакты, макеты, подключить аналитику и т.д. Ну, т.е. все, что нужно настроить - надо настроить.</li>
				<li><span className='font-bold'>Проверяем как работает сайт</span>. Когда все закончишь, проверь как отображаются страницы - все страницы, в том числе на мобильных устройствах. Правильно ли внесен контент (текты без ошибок, адекватные картинки, актуальные контакты), отправляются ли сообщения из форм обратной связи.</li>
			</ul>

			<p>В целом как-то так. Остальное смотри по ходу. На вскидку, если контент уже готов, то с учетом времени на освоение конструктора, ты сможешь запустить сайта в течение 2-7 дней.</p>

			<h2>Какие ограничения</h2>

			<p>Несмотря на то, что конструктор REG.site, по сути является полноценным сайто на WordPess, у него все же есть ограничения. Напишу те, про которые тебе обязательно надо знать.</p>

			<ul>
				<li><span className='font-bold'>Ограниченный выбор шаблонов</span>. Чтобы конструктор работал, как конструктор можно использовать только фирменные шаблоны Divi. Их много, но все может быть такое, что ни один не понравится.</li>
				<li><span className='font-bold'>Шаблоны и контент</span>. При смене шаблона весь внесенный через визуальный редактор контент пропадает. Т.е. тебе сначала нужно железно определиться с шаблоном, и только потом вносить контент. Иначе придется все повторить на другом шаблоне.</li>
			</ul>

			<p>Есть и другие ограничения. О них ты можешь почитать в документации (ссылки найдешь выше).</p>

			<h2>Стоимость</h2>

			<p>Стоимость, если сравнивать с другими конструкорами, вполне конкурентная. А учитывая, что это полноценный сайт, то в чем-то даже более выгодная. Тут много писать не вижу смысла, потому что цены это все относительно и надо сравнивать по многим параметрам. Смотри самостоятельно насколько тебе это подходит. Суммы в любом случае, вполне подъемные, если при помощи сайта ты получаешь хотя бы в три раза больше чем платишь за него.</p>

			<h2>Развитие сайта</h2>

			<p>Любой сайт требует развития. Чтобы был постоянный поток посетителей и тем более покупателей надо периодически добавлять и обновлять контент. Это основное. Также можно время от времени менять дизайн полностью или частично. Это все показывает, что сайт живой, что за ним стоят люди, которые развивают свое дело, это уже добавляет доверия.</p>

			<p>Сайт на конструкторе, на мой вгляд, больше подходит для краткосрочных проектов, для исследования рынка, для каких-то мероприятий, которые прошли и про них забыли, для рекламных акций, тоже привязанных к датам. Серьезные долгосрочные проекты лучше делать продуманно, заказывать это профессионалам, делать уникальный дизайн, прописывать долгосрочную концепцию и цели.</p>

			<p>Плюс конструкора REG.site в том, что когда твое дело потребует от сайта больше функций и мощностей, то текущий сайт с минимальными затратами можно перенести из конструктора в отдельный проект и продолжать развивать его.</p>

			<h2>Главные выводы</h2>

			<p>Я поковырял этот конструктор пару часов прежде чем предлагать его тебе. И вот какое впечатление у меня осталось. Мне понравилось, что это полноценный сайт, да еще и на WordPess. Про конструктор страниц Divi могу сказать, что он неплох, действительно все можно настроить и есть достаточное количество готовых модулей из которых ты можешь нагородить разной красоты. Однако для того, чтобы прямо бегло с этим работать все же придется потратить время на изучение. Имей в виду.</p>

			<p>В общем, возьми и попробуй. Для новичков там есть тариф за 1 рубль на 7 дней. Если вдруг не зайдет или будет лень, то мы можем сделать тебе сайт на WordPess - <a href='/website-on-wordpress'>смотри в услугах</a>.</p>

		</ContentCenter>
	</Layout>
)

export default WebsiteBuilder